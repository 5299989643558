import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"

import { ListNews, ListHospitalization, ListAbout, ListAppointment } from "../"

export const PcFooterLink = () => {
  const { t } = useTranslation()

  return (
    <div className="pc">
      <div className="container2">
        <div className="pc_link_row">
          <div className="pc_link_area">
            <div className="f_link_box">
              <h3>
                <Link to="/appointment_menu">
                  {t("外来受診のご案内", "外来受診のご案内")}
                </Link>
              </h3>
              <ul>
                <ListAppointment />
              </ul>
            </div>
          </div>
          <div className="pc_link_area">
            <div className="f_link_box">
              <h3>
                <Link to="/our_hospital_menu">
                  {t("当院について", "当院について")}
                </Link>
              </h3>
              <ul>
                {" "}
                <ListAbout />
              </ul>
            </div>
          </div>
          <div className="pc_link_area">
            <div className="f_link_box">
              <h3>
                <Link to="/hospitalization_guidance">
                  {t("入院のご案内", "入院のご案内")}
                </Link>
              </h3>
              <ul>
                <ListHospitalization />
              </ul>
            </div>
          </div>
          <div className="pc_link_area">
            <div className="f_link_box">
              <h3>
                <Link to="/news">{t("ニュース一覧", "ニュース一覧")}</Link>
              </h3>
              <ul>
                <ListNews />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
