import { useTranslation } from "react-i18next"

export const RecruitNavi = () => {
  const { t } = useTranslation()

  return (
    <nav className="side_nav">
      <div className="sticky">
        <h2>{t("採用情報", "採用情報")}</h2>
        <ul>
          <li>
            <a href="#doctor">{t("医師", "医師")}</a>
          </li>
          <li>
            <a href="#nurce">{t("看護師", "看護師")}</a>
          </li>
          <li>
            <a href="#pharmacist">{t("薬剤師", "薬剤師")}</a>
          </li>
          <li>
            <a href="#orthoptist">{t("視能訓練士", "視能訓練士")}</a>
          </li>
          <li>
            <a href="#other_staff">
              {t("コメディカルスタッフ", "コメディカルスタッフ")}
            </a>
          </li>
        </ul>
      </div>
    </nav>
  )
}
