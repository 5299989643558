import { useTranslation } from "react-i18next"

import { ListNews, ListHospitalization, ListAbout, ListAppointment } from "../"

export const MoFooterLink = () => {
  const { t } = useTranslation()

  return (
    <div className="mo">
      <div className="f_link_box">
        <h3
          data-toggle="collapse"
          role="button"
          aria-expanded="false"
          href="#q1"
          aria-controls="q1"
        >
          {t("外来受診のご案内", "外来受診のご案内")}
        </h3>
        <ul className="collapse" id="q1">
          <ListAppointment />
        </ul>
      </div>
      <div className="f_link_box">
        <h3
          data-toggle="collapse"
          role="button"
          aria-expanded="false"
          href="#q2"
          aria-controls="q2"
        >
          {t("当院について", "当院について")}
        </h3>
        <ul className="collapse" id="q2">
          <ListAbout />
        </ul>
      </div>

      <div className="f_link_box">
        <h3
          data-toggle="collapse"
          role="button"
          aria-expanded="false"
          href="#q3"
          aria-controls="q3"
        >
          {t("入院のご案内", "入院のご案内")}
        </h3>
        <ul className="collapse" id="q3">
          <ListHospitalization />
        </ul>
      </div>
      <div className="f_link_box">
        <h3
          data-toggle="collapse"
          role="button"
          aria-expanded="false"
          href="#q4"
          aria-controls="q4"
        >
          {t("ニュース一覧", "ニュース一覧")}
        </h3>
        <ul className="collapse" id="q4">
          <ListNews />
        </ul>
      </div>
    </div>
  )
}
