import { useLocalization } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"

import { LocalizedImage } from "components/LocalizedImage"

/**
 * バナーエリア
 */
export const BannerArea = ({ isInviewfadeInUp = true }) => {
  const { locale } = useLocalization()
  const { t } = useTranslation()

  return (
    <div className={`bnr_area ${isInviewfadeInUp && "inviewfadeInUp"}`}>
      <ul>
        <li className={`${isInviewfadeInUp && "inviewfadeInUp"} delay_2`}>
          <a
            href="http://www.hoshii-contact.jp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LocalizedImage
              path="/img/service/bnr_hosii_contact_lens.jpg"
              locale={locale}
              className="pc"
              alt={t("星井コンタクトレンズ", "星井コンタクトレンズ")}
              loading="lazy"
            />
            <LocalizedImage
              path="/img/service/bnr_hosii_contact_lens_mobile.jpg"
              locale={locale}
              className="mo"
              alt={`${t("星井コンタクトレンズ", "星井コンタクトレンズ")} ${t(
                "モバイル",
                "モバイル",
              )}`}
              loading="lazy"
            />
          </a>
        </li>
      </ul>
    </div>
  )
}
