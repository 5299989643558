/**
 *  ベージの先頭に戻る
 */
export const PageTopAnchor = () => (
  <p className="page_t_link" id="page-top">
    <a href="#top">
      page
      <br />
      top
      <br />
      <i className="fa fa-arrow-up" aria-hidden="true"></i>
    </a>
  </p>
)
