import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"

import { LocalizedImage } from "components/LocalizedImage"

export const MoFooterWrap = () => {
  const { t } = useTranslation()

  return (
    <div className="mo_footer_wrap">
      <div className="mo_footer" id="mo_footer">
        <div className="mo_icon_area">
          <div className="footer_icon">
            <Link to="/appointment_menu">
              <p className="icon">
                <LocalizedImage
                  path="/img/top/outpatient.png"
                  alt={t("外来のご案内", "外来のご案内")}
                />
              </p>
              <p className="text">{t("外来のご案内", "外来のご案内")}</p>
            </Link>
          </div>
          <div className="footer_icon">
            <Link to="/hospitalization_guidance">
              <p className="icon">
                <LocalizedImage
                  path="/img/top/hospitalization.png"
                  alt={t("入院/面会のご案内", "入院/面会のご案内")}
                />
              </p>
              <p className="text">
                {t("入院/面会のご案内", "入院/面会のご案内")}
              </p>
            </Link>
          </div>
          <div className="footer_icon">
            <Link to="/institutions">
              <p className="icon">
                <LocalizedImage
                  path="/img/top/hospital.png"
                  alt={t("医療機関の皆さまへ", "医療機関の皆さまへ")}
                />
              </p>
              <p className="text">
                {t("医療機関の皆さまへ", "医療機関の皆さまへ")}
              </p>
            </Link>
          </div>
          <div className="footer_icon">
            <a
              href="http://www.hoshii-contact.jp/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="icon">
                <LocalizedImage
                  path="/img/top/contact_lens.png"
                  alt={t("コンタクトレンズ", "コンタクトレンズ")}
                />
              </p>
              <p className="text">
                {t("コンタクトレンズ", "コンタクトレンズ")}
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
