import { LocalizedLink as Link, useLocalization } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"

export const PhoneArea = ({ isInviewfadeInUp = true }) => {
  const { locale } = useLocalization()
  const { t } = useTranslation()

  return (
    <div className={`phone_area ${isInviewfadeInUp && "inviewfadeInUp"}`}>
      <div className="row align-items-center">
        <div className="col-12 col-md-6">
          <div className="phone_box">
            <table className="info_table">
              <tbody>
                <tr>
                  <td>
                    <i className="fas fa-map-marker-alt" />
                  </td>
                  <td>
                    <span />
                    {t("住所", "住所")}
                  </td>
                  <td>
                    {t("〒880-0904", "〒880-0904")}
                    <br />
                    {t(
                      "宮崎県宮崎市中村東3丁目5番10号",
                      "宮崎県宮崎市中村東3丁目5番10号",
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <i className="fas fa-phone-office"></i>
                  </td>
                  <td>{t("お問い合わせ", "お問い合わせ")}</td>
                  <td>
                    {t("Tel (0985)51-1123", "Tel (0985)51-1123")}
                    <br />
                    {t("Fax (0985)51-1134", "Fax (0985)51-1134")}
                  </td>
                </tr>
                <tr>
                  <td>
                    <i className="fas fa-clock"></i>
                  </td>
                  <td>{t("受付時間", "受付時間")}</td>
                  <td>
                    {t("(月～土)")}
                    <br />
                    {t("8_30～12_00/13_30～17_30", "8:30～12:00/13:30～17:30")}
                    <br />
                    {t(
                      "(土曜日は午前のみ、水曜日は19時まで)",
                      "(土曜日は午前のみ、水曜日は19時まで)",
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <i className="fas fa-calendar-alt"></i>
                  </td>
                  <td>{t("休診日", "休診日")}</td>
                  <td>
                    {t(
                      "日曜日、祝日、年末年始",
                      "日曜日、祝日、年末年始",
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="link_table">
              <tbody>
                <tr>
                  <td className="appointment_btn">
                    <Link to="/appointment_menu">
                      {t("受診について見る", "受診について見る")}
                    </Link>
                  </td>
                  <td className="access_btn">
                    <Link to="/access">
                      {t("交通アクセスを見る", "交通アクセスを見る")}
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="phone_box">
            <div className="map">
              <iframe
                src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3387.471053972259!2d131.4151487151599!3d31.893796381247586!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3538b7a3eabeacdf%3A0xa4e69d0f8a92df39!2z44CSODgwLTA5MDQg5a6u5bSO55yM5a6u5bSO5biC5Lit5p2R5p2x77yT5LiB55uu77yV4oiS77yR77yQ!5e0!3m2!1s${locale}!2sjp!4v1638266037570!5m2!1s${locale}!2sjp`}
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
