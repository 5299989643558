import { useTranslation } from "react-i18next"
import { useLocalization } from "gatsby-theme-i18n"

import { LocalizedImage } from "components/LocalizedImage"

const Footer = () => {
  const { t } = useTranslation()
  const { locale } = useLocalization()

  return (
    <footer className="inviewfadeInUp fadeInUp">
      <p className="footer_logo">
        <a
          href="http://www.fujimoto.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LocalizedImage
            path="/img/logo_footer.png"
            locale={locale}
            alt={t("藤元メディカルシステム", "藤元メディカルシステム")}
          />
        </a>
      </p>
      <p>
        <a
          href="http://www.fujimoto.com/corporate/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t(
            "藤元メディカルシステムについて",
            "藤元メディカルシステムについて",
          )}
        </a>
        ｜
        <a
          href="http://www.fujimoto.com/services/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("施設のご案内", "施設のご案内")}
        </a>
        ｜
        <a
          href="http://www.recruit.fujimoto.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("採用情報", "採用情報")}
        </a>
        ｜
        <a
          href="http://www.fujimoto.com/contact/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("お問い合わせ", "お問い合わせ")}
        </a>
        ｜
        <a
          href="http://www.fujimoto.com/privacy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("プライバシーポリシー", "プライバシーポリシー")}
        </a>
      </p>
      <p className="copy">
        Copyright &copy; Fujimoto Medical System All Rights Reserved.
      </p>
    </footer>
  )
}

export default Footer
