import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"

/**
 * ニュースの一覧
 */
export const ListNews = () => {
  const { t } = useTranslation()

  return (
    <>
      <li>
        <Link to="/news">{t("すべてのお知らせ", "すべてのお知らせ")}</Link>
      </li>
      <li>
        <Link to="/news/announcement">{t("お知らせ", "お知らせ")}</Link>
      </li>
      <li>
        <Link to="/news/media">{t("メディア情報", "メディア情報")}</Link>
      </li>
      <li>
        <Link to="/news/closed">{t("休診のお知らせ", "休診のお知らせ")}</Link>
      </li>
      <li>
        <Link to="/news/important">
          {t("重要なお知らせ", "重要なお知らせ")}
        </Link>
      </li>
    </>
  )
}

/**
 * 入院のご案内
 */
export const ListHospitalization = () => {
  const { t } = useTranslation()

  return (
    <>
      <li>
        <Link to="/hospitalization_guidance/guidance_01">
          {t("準備していただくもの", "準備していただくもの")}
        </Link>
      </li>
      <li>
        <Link to="/hospitalization_guidance/guidance_02">
          {t("お食事について", "お食事について")}
        </Link>
      </li>
      <li>
        <Link to="/hospitalization_guidance/guidance_03">
          {t("付き添いについて", "付き添いについて")}
        </Link>
      </li>
      <li>
        <Link to="/hospitalization_guidance/guidance_04">
          {t("ご面会", "ご面会")}
        </Link>
      </li>
      <li>
        <Link to="/hospitalization_guidance/guidance_05">
          {t("消灯時間", "消灯時間")}
        </Link>
      </li>
      <li>
        <Link to="/hospitalization_guidance/guidance_06">
          {t("その他", "その他")}
        </Link>
      </li>
    </>
  )
}

/**
 * 当院について
 */
export const ListAbout = () => {
  const { t } = useTranslation()

  return (
    <>
      <li>
        <Link to="/our_hospital_menu/Introducing_the_clinic">
          {t("医院のご紹介", "医院のご紹介")}
        </Link>
      </li>
      <li>
        <Link to="/our_hospital_menu/message_from_the_director">
          {t("院長からのあいさつ", "院長からのあいさつ")}
        </Link>
      </li>
      <li>
        <Link to="/our_hospital_menu/medical_equipment">
          {t("使用機器のご紹介", "使用機器のご紹介")}
        </Link>
      </li>
      <li>
        <Link to="/our_hospital_menu/surgical_record">
          {t("手術実績", "手術実績")}
        </Link>
      </li>
      <li>
        <Link to="/floor_guide_menu/floor_map">
          {t("フロアマップ", "フロアマップ")}
        </Link>
      </li>
      <li>
        <Link to="/floor_guide_menu/google_map">
          {t("施設内のグーグルマップ", "施設内のグーグルマップ")}
        </Link>
      </li>
      <li>
        <Link to="/our_hospital_menu/children_glasses">
          {t("子供の治療用メガネ", "子供の治療用メガネ")}
          {t("(斜視・弱視)", "(斜視・弱視)")}
        </Link>
      </li>
      <li>
        <a
          href="https://www.santen.co.jp/ja/healthcare/eye/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("Santen(目の情報ポータル)", "Santen(目の情報ポータル)")}
        </a>
      </li>
      <li>
        <Link to="/privacy_policy">
          {t("個人情報保護方針", "個人情報保護方針")}
        </Link>
      </li>
      <li>
        <Link to="/institutions">
          {t("医療機関の皆さまへ", "医療機関の皆さまへ")}
        </Link>
      </li>
    </>
  )
}

/**
 * 外来受診のご案内
 */
export const ListAppointment = () => {
  const { t } = useTranslation()

  return (
    <>
      <li>
        <Link to="/appointment_menu/about_appointment">
          {t("ご予約について", "ご予約について")}
        </Link>
      </li>
      <li>
        <Link to="/appointment_menu/doctor_s_schedule">
          {t("医師ごとの診療時間表", "医師ごとの診療時間表")}
        </Link>
      </li>
      <li>
        <Link to="/appointment_menu/doctor_timetable">
          {t("月間診療予定", "月間診療予定")}
        </Link>
      </li>
    </>
  )
}
